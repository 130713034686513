








































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileUpdate } from '@/interfaces';
import { dispatchUpdateUserProfile, readUserProfile } from '@/store/main/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class UserProfileEdit extends Vue {
  public valid = true;
  public password1 = '';
  public password2 = '';
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const updatedProfile: IUserProfileUpdate = {};
      updatedProfile.password = this.password1;
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
      this.$router.push('/main/profile');
    }
  }
}
